import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppLayout from '../../components/AppLayout';
import Container from '@material-ui/core/Container';
import { AxiosResponse } from 'axios';
import { captureSentryError } from '../../plugins/sentry';
import ErrorHandler from '../../services/errors';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import AccountApi from '../../services/apis/AccountApi';
import ChartOfAccounts, {
    SerializedChartOfAccounts,
} from '../../models/chartOfAccounts';
import { useSnackbar } from 'notistack';
import LoadingSpinner from '../../components/LoadingSpinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AccountBalanceChip from '../../components/AccountBalanceChip';
import AccountBalanceWithTooltip from '../../components/AccountBalanceWithTooltip';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    head: {
        backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },
    cellStart: {
        paddingLeft: theme.spacing(8),
    },
    cellEnd: {
        paddingRight: theme.spacing(8),
    },
}));

export default function AccountsIndex() {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [accounts, setAccounts] = React.useState(
        [] as Array<ChartOfAccounts>
    );
    const { enqueueSnackbar } = useSnackbar();

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number
    ) => {
        setActiveTab(newValue);
    };

    const loadChartOfAccounts = (organisationId: string) => {
        setIsLoading(true);
        AccountApi.index(organisationId, true)
            .then((response: AxiosResponse) => {
                setAccounts(
                    response.data.payload.map(
                        (account: SerializedChartOfAccounts) => {
                            return new ChartOfAccounts(account);
                        }
                    )
                );
                setIsLoading(false);
            })
            .catch((error: Error) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
            });
    };

    const organisationId = useSelector(
        (state: GlobalState) => state.organisationId
    );

    const accountIsVisible = (account: ChartOfAccounts) => !account.hidden;

    useEffect(() => {
        if (organisationId) {
            loadChartOfAccounts(organisationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLayout title="Chart Of Accounts">
            <Container maxWidth={false} className={classes.container}>
                {accounts.length > 0 && (
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                aria-label="chart of accounts"
                            >
                                {accounts.map(
                                    (
                                        account: ChartOfAccounts,
                                        index: number
                                    ) => {
                                        return (
                                            <Tab
                                                key={account.id}
                                                label={account.name}
                                                {...a11yProps(index)}
                                            />
                                        );
                                    }
                                )}
                            </Tabs>
                        </AppBar>
                        {accounts.map(
                            (account: ChartOfAccounts, index: number) => {
                                return (
                                    <TabPanel
                                        key={account.id}
                                        value={activeTab}
                                        index={index}
                                    >
                                        <TableContainer
                                            component={Paper}
                                            style={{ marginTop: 16 }}
                                        >
                                            <Table aria-label="accounts table">
                                                {account.children.map(
                                                    (
                                                        subAccount: ChartOfAccounts
                                                    ) => {
                                                        return [
                                                            <TableHead
                                                                className={
                                                                    classes.head
                                                                }
                                                                key={
                                                                    'head' +
                                                                    subAccount.id
                                                                }
                                                            >
                                                                <TableRow>
                                                                    <TableCell
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                    >
                                                                        <Box
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                            p={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                component="h6"
                                                                                variant="h6"
                                                                            >
                                                                                {
                                                                                    subAccount.name
                                                                                }
                                                                            </Typography>
                                                                            {subAccount.accountBalance && (
                                                                                <AccountBalanceChip
                                                                                    size="medium"
                                                                                    balance={
                                                                                        subAccount.accountBalance
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>,
                                                            <TableBody
                                                                key={
                                                                    'body' +
                                                                    subAccount.id
                                                                }
                                                            >
                                                                {subAccount.children
                                                                    .filter(
                                                                        accountIsVisible
                                                                    )
                                                                    .map(
                                                                        (
                                                                            subSubAccount: ChartOfAccounts
                                                                        ) => (
                                                                            <TableRow
                                                                                key={
                                                                                    subSubAccount.id
                                                                                }
                                                                            >
                                                                                <TableCell
                                                                                    className={
                                                                                        classes.cellStart
                                                                                    }
                                                                                >
                                                                                    <Typography variant="subtitle1">
                                                                                        {
                                                                                            subSubAccount.name
                                                                                        }{' '}
                                                                                        (
                                                                                        {
                                                                                            subSubAccount.id
                                                                                        }

                                                                                        )
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography
                                                                                        color="textSecondary"
                                                                                        variant="subtitle2"
                                                                                    >
                                                                                        {
                                                                                            subSubAccount.description
                                                                                        }
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                {subSubAccount.accountBalance && (
                                                                                    <TableCell>
                                                                                        <AccountBalanceWithTooltip
                                                                                            balance={
                                                                                                subSubAccount.accountBalance
                                                                                            }
                                                                                        />
                                                                                    </TableCell>
                                                                                )}
                                                                            </TableRow>
                                                                        )
                                                                    )}
                                                            </TableBody>,
                                                        ];
                                                    }
                                                )}
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                );
                            }
                        )}
                    </div>
                )}
                {(accounts.length === 0 || isLoading) && <LoadingSpinner />}
            </Container>
        </AppLayout>
    );
}
