import React, {
    useState,
    ChangeEvent,
    useEffect,
    FormEvent,
    MouseEvent,
} from 'react';
import AppLayout from 'components/AppLayout';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Add';
import { Card, CardContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import ErrorHandler from 'services/errors';
import { captureSentryError } from '../../plugins/sentry';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CurrencySelector from '../../components/CurrencySelector';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import CurrencyPair, {
    SerializedCurrencyPair,
} from '../../models/CurrencyPair';
import CurrencyPairApi, {
    NewCurrencyPairRequest,
} from '../../services/apis/CurrencyPairApi';
import LoadingSpinner from '../../components/LoadingSpinner';
import Typography from '@material-ui/core/Typography';
import OrganisationApi from '../../services/apis/OrganisationApi';
import Organisation from '../../models/organisation';
import ValidationErrors from '../../services/errors/ValidationErrors';
import Currency from '../../models/currency';
import DeleteIcon from '@material-ui/icons/Delete';

const makeCreateCurrencyPairRequest = (): NewCurrencyPairRequest => {
    return {
        to_currency: '',
        from_currency: '',
        rate: 0,
        captcha: '',
        valid_from: null,
        valid_until: null,
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(2),
    },
    loadingSpinner: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        marginTop: 5,
        marginLeft: -12,
    },
    noRows: {
        textAlign: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    gridContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
    },
}));

export default function CurrencyPairIndex() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [rows, setRows] = useState<Array<CurrencyPair>>([]);
    const [loadingRows, setLoadingRows] = useState<boolean>(true);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loadingOrganisation, setLoadingOrganisation] =
        useState<boolean>(true);
    const [formErrors, setFormErrors] = useState<ValidationErrors>(
        new ValidationErrors()
    );
    const [currencyPair, setCurrencyPair] = useState<NewCurrencyPairRequest>(
        makeCreateCurrencyPairRequest()
    );

    const handleSentryError = (error: AxiosError) => {
        captureSentryError(error);
        enqueueSnackbar(ErrorHandler.errorMessage(error), {
            variant: 'error',
        });
    };

    const organisationId = useSelector(
        (state: GlobalState) => state.organisationId
    );

    const loadOrganisation = (organisationId: string) => {
        setLoadingOrganisation(true);
        OrganisationApi.show(organisationId)
            .then((response) => {
                const organisation = new Organisation(response.data.payload);
                setLoadingOrganisation(false);
                setCurrencyPair({
                    ...currencyPair,
                    to_currency: organisation.currency.symbol,
                });
            })
            .catch(handleSentryError);
    };

    const loadCurrencyPairs = (organisationId: string) => {
        setLoadingRows(true);
        CurrencyPairApi.index(organisationId)
            .then((response: AxiosResponse) => {
                setRows(
                    response.data.payload.map(
                        (currencyPair: SerializedCurrencyPair) => {
                            return new CurrencyPair(currencyPair);
                        }
                    )
                );
                setLoadingRows(false);
            })
            .catch(handleSentryError);
    };

    const onCurrencyPairAdd = (event: FormEvent) => {
        event.preventDefault();
        setLoadingForm(true);
        setFormErrors(new ValidationErrors());
        if (executeRecaptcha) {
            executeRecaptcha('create_currency_pair')
                .then((captcha: string) => {
                    CurrencyPairApi.create(organisationId as string, {
                        ...currencyPair,
                        captcha,
                    })
                        .then(() => {
                            setCurrencyPair({
                                ...makeCreateCurrencyPairRequest(),
                                to_currency: currencyPair.to_currency,
                            });
                            loadCurrencyPairs(organisationId as string);
                        })
                        .catch((error: AxiosError) => {
                            handleSentryError(error);
                            setFormErrors(ErrorHandler.getFormErrors(error));
                        })
                        .finally(() => {
                            setLoadingForm(false);
                        });
                })
                .catch((error: AxiosError) => {
                    handleSentryError(error);
                    setLoadingForm(false);
                });
        }
    };

    const deleteCurrencyPair = (pairId: any) => {
        setLoadingForm(true);
        CurrencyPairApi.delete(organisationId as string, pairId)
            .then((response: AxiosResponse) => {
                enqueueSnackbar(response.data.message, { variant: 'success' });
                loadCurrencyPairs(organisationId as string);
            })
            .catch(handleSentryError)
            .finally(() => {
                setLoadingForm(false);
            });
    };

    useEffect(() => {
        if (organisationId) {
            loadCurrencyPairs(organisationId);
            loadOrganisation(organisationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'fromCurrency',
            headerName: 'From Currency',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                const currency = params.value;
                if (currency instanceof Currency) {
                    return currency.symbol;
                }
                return 'Unavailable';
            },
        },
        {
            field: 'toCurrency',
            headerName: 'To Currency',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                const currency = params.value;
                if (currency instanceof Currency) {
                    return currency.symbol;
                }
                return 'Unavailable';
            },
        },
        { field: 'rate', headerName: 'Rate', flex: 1 },
        {
            field: 'validFrom',
            headerName: 'Valid From',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                const date = params.value;
                if (date instanceof Date) {
                    return date.toLocaleString();
                }
                return '-';
            },
        },
        {
            field: 'validUntil',
            headerName: 'Valid Until',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                const date = params.value;
                if (date instanceof Date) {
                    return date.toLocaleString();
                }
                return '-';
            },
        },
        {
            field: 'rates',
            headerName: 'Actions',
            flex: 0.7,
            renderCell: (params: GridCellParams) => (
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loadingForm}
                    onClick={(event: MouseEvent) => {
                        event.preventDefault();
                        deleteCurrencyPair(params.row.id);
                    }}
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <AppLayout title="Currency Pairs">
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <form
                                    className={classes.form}
                                    noValidate
                                    onSubmit={onCurrencyPairAdd}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <CurrencySelector
                                                label="From Currency"
                                                disabled={
                                                    loadingForm ||
                                                    loadingOrganisation
                                                }
                                                error={formErrors.has(
                                                    'from_currency'
                                                )}
                                                helperText={formErrors.first(
                                                    'from_currency'
                                                )}
                                                value={
                                                    currencyPair.from_currency
                                                }
                                                onChange={(
                                                    event: ChangeEvent<
                                                        | HTMLTextAreaElement
                                                        | HTMLInputElement
                                                    >
                                                ) => {
                                                    setCurrencyPair({
                                                        ...currencyPair,
                                                        from_currency:
                                                            event.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <CurrencySelector
                                                label="To Currency"
                                                disabled={true}
                                                error={formErrors.has(
                                                    'to_currency'
                                                )}
                                                helperText={formErrors.first(
                                                    'to_currency'
                                                )}
                                                value={currencyPair.to_currency}
                                                onChange={(
                                                    event: ChangeEvent<
                                                        | HTMLTextAreaElement
                                                        | HTMLInputElement
                                                    >
                                                ) => {
                                                    setCurrencyPair({
                                                        ...currencyPair,
                                                        to_currency:
                                                            event.target.value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                disabled={
                                                    loadingForm ||
                                                    loadingOrganisation
                                                }
                                                error={formErrors.has('rate')}
                                                helperText={formErrors.first(
                                                    'rate'
                                                )}
                                                name="text"
                                                margin="normal"
                                                type="number"
                                                label="Rate"
                                                placeholder="Rate e.g 41.9341"
                                                variant="outlined"
                                                value={currencyPair.rate}
                                                onChange={(event: any) => {
                                                    setCurrencyPair({
                                                        ...currencyPair,
                                                        rate: event.target
                                                            .value,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={
                                                    loadingForm ||
                                                    loadingOrganisation
                                                }
                                                error={formErrors.has(
                                                    'valid_from'
                                                )}
                                                helperText={formErrors.first(
                                                    'valid_from'
                                                )}
                                                label="Valid From"
                                                variant="outlined"
                                                margin="normal"
                                                name="valid_from"
                                                type="datetime-local"
                                                onChange={(
                                                    event: ChangeEvent<HTMLTextAreaElement>
                                                ) => {
                                                    setCurrencyPair({
                                                        ...currencyPair,
                                                        valid_from: event.target
                                                            .value
                                                            ? new Date(
                                                                  event.target.value
                                                              )
                                                            : null,
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                disabled={
                                                    loadingForm ||
                                                    loadingOrganisation
                                                }
                                                error={formErrors.has(
                                                    'valid_to'
                                                )}
                                                helperText={formErrors.first(
                                                    'valid_to'
                                                )}
                                                label="Valid Until"
                                                variant="outlined"
                                                margin="normal"
                                                name="timestamp"
                                                type="datetime-local"
                                                onChange={(
                                                    event: ChangeEvent<HTMLTextAreaElement>
                                                ) => {
                                                    setCurrencyPair({
                                                        ...currencyPair,
                                                        valid_until: event
                                                            .target.value
                                                            ? new Date(
                                                                  event.target.value
                                                              )
                                                            : null,
                                                    });
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={4} xl={2}>
                                            <Button
                                                type="submit"
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    loadingForm ||
                                                    loadingOrganisation
                                                }
                                                startIcon={<CreateIcon />}
                                                className={classes.submit}
                                            >
                                                {loadingForm && (
                                                    <CircularProgress
                                                        size={24}
                                                        className={
                                                            classes.loadingSpinner
                                                        }
                                                    />
                                                )}
                                                Create
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        {loadingRows && <LoadingSpinner />}
                        {!loadingRows && rows.length > 0 && (
                            <Grid
                                item
                                xs={12}
                                className={classes.gridContainer}
                            >
                                <DataGrid
                                    rows={rows}
                                    autoHeight={true}
                                    pageSize={25}
                                    columns={columns}
                                />
                            </Grid>
                        )}
                        {!loadingRows && rows.length === 0 && (
                            <Grid item xs={12} md={5}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            variant="h5"
                                            component="h2"
                                            className={classes.noRows}
                                        >
                                            You don't have any currency pairs...
                                            yet
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    );
}
