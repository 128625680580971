import { configureStore, createSlice } from '@reduxjs/toolkit';
import { SerializedUser } from 'models/user';

const LOCAL_STORAGE = {
    STATE_NAV_OPEN: 'STATE_NAV_OPEN',
    STATE_TIMEZONE: 'STATE_TIMEZONE',
};

export interface GlobalState {
    user: SerializedUser | null;
    organisationId: string | null;
    navOpen: boolean;
    timezone: string | null;
}

const store = createSlice({
    name: 'store',
    initialState: {
        user: localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user') || '')
            : null,
        organisationId:
            localStorage.getItem('organisationId') &&
            localStorage.getItem('organisationId') !== 'undefined'
                ? JSON.parse(localStorage.getItem('organisationId') || '')
                : null,
        timezone:
            localStorage.getItem(LOCAL_STORAGE.STATE_TIMEZONE) &&
            localStorage.getItem(LOCAL_STORAGE.STATE_TIMEZONE) !== 'undefined'
                ? JSON.parse(
                      localStorage.getItem(LOCAL_STORAGE.STATE_TIMEZONE) || ''
                  )
                : null,
        navOpen: localStorage.getItem(LOCAL_STORAGE.STATE_NAV_OPEN)
            ? JSON.parse(
                  localStorage.getItem(LOCAL_STORAGE.STATE_NAV_OPEN) || 'true'
              )
            : true,
    } as GlobalState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        setOrganisationId: (state, action) => {
            state.organisationId = action.payload;
            localStorage.setItem(
                'organisationId',
                JSON.stringify(action.payload)
            );
        },
        setTimezone: (state, action) => {
            state.timezone = action.payload;
            localStorage.setItem(
                LOCAL_STORAGE.STATE_TIMEZONE,
                JSON.stringify(action.payload)
            );
        },

        setNavOpen: (state, action) => {
            state.navOpen = action.payload;
            localStorage.setItem(
                LOCAL_STORAGE.STATE_NAV_OPEN,
                JSON.stringify(action.payload)
            );
        },

        refreshOrganisations: (state) => {},
    },
});

export const { setUser, setOrganisationId, setNavOpen, setTimezone } =
    store.actions;

export default configureStore({
    reducer: store.reducer,
});
