import React, { MouseEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, Paper } from '@material-ui/core';
import currency from '../filters/currency';
import { useNavigate } from 'react-router-dom';
import CONSTANTS from 'consts';
import { createOrganisationRoute } from '../store/selectors';
import { useSelector } from 'react-redux';
import { GlobalState } from '../store';
import Currency from '../models/currency';

const useStyles = makeStyles((theme: Theme) => ({
    depositContext: {
        flex: 1,
    },
    fixedHeight: {
        height: 240,
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export interface DashboardCardProps {
    label: string;
    amount: number;
    secondaryLabel?: string;
    currency: Currency;
}

export default function DashboardCard(props: DashboardCardProps) {
    const classes = useStyles();
    const navigate = useNavigate();

    const globalState = useSelector((state: GlobalState) => state);

    const navigateToTransactions = (event: MouseEvent) => {
        event.preventDefault();
        navigate(
            createOrganisationRoute(
                globalState,
                CONSTANTS.ROUTES.PATHS.TRANSACTIONS.INDEX
            )
        );
    };

    const currentDate = () => {
        return new Date().toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
        });
    };

    return (
        <Paper className={classes.fixedHeight}>
            <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
            >
                {props.label}
            </Typography>
            <Typography component="p" variant="h4">
                {currency(props.amount, props.currency)}
            </Typography>
            <Typography
                color="textSecondary"
                className={classes.depositContext}
            >
                {props.secondaryLabel ?? currentDate()}
            </Typography>
            <div>
                <Link color="primary" href="#" onClick={navigateToTransactions}>
                    View Transactions
                </Link>
            </div>
        </Paper>
    );
}
