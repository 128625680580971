import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';

export interface CreateResponse {
    message: string;
    payload: {
        name: string;
        created_at: string;
        updated_at: string;
        owner_id: string;
        id: string;
    };
}

class AccountApi extends BaseApi {
    create(
        organisationId: string,
        captcha: string
    ): Promise<AxiosResponse<CreateResponse>> {
        return this.authPost(`/${organisationId}/accounts`, { captcha });
    }

    index(
        organisationId: string,
        withTransactions: boolean = false
    ): Promise<AxiosResponse> {
        return this.authGet(
            `/${organisationId}/accounts?withTransactions=${withTransactions}`
        );
    }

    timeSeries(
        organisationId: string,
        ticks: Array<Date>
    ): Promise<AxiosResponse> {
        return this.authGet(`/${organisationId}/accounts/time-series`, {
            Ticks: ticks.map((tick) => tick.toISOString()),
        });
    }
}

const client = new AccountApi();
export default client;
