import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';

export interface CreateResponse {
    message: string;
    payload: {
        name: string;
        created_at: string;
        updated_at: string;
        owner_id: string;
        id: string;
    };
}

class OrganisationApi extends BaseApi {
    create(
        name: string,
        currency: string,
        timezone: string,
        captcha: string
    ): Promise<AxiosResponse<CreateResponse>> {
        return this.authPost('/organisations', {
            name,
            currency,
            captcha,
            timezone,
        });
    }

    show(organisationId: string): Promise<AxiosResponse> {
        return this.authGet(`/organisations/${organisationId}`);
    }

    index(): Promise<AxiosResponse> {
        return this.authGet('/organisations');
    }
}

const client = new OrganisationApi();
export default client;
