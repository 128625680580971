import { AxiosResponse } from 'axios';
import BaseApi, { IndexOptions } from './BaseApi';

export interface NewTransaction {
    description: string;
    reference: string;
    notes: string;
    timestamp: string;
    direction: string;
    account_id: string;
    currency: string;
    amount: number;
    source: string;
}

export interface NewGroupedTransaction extends NewTransaction {
    children: Array<NewTransaction>;
}

export interface CreateTransactionRequest extends NewGroupedTransaction {
    captcha: string;
}

class TransactionApi extends BaseApi {
    create(
        organisationId: string,
        input: CreateTransactionRequest
    ): Promise<AxiosResponse> {
        return this.authPost(`/${organisationId}/transactions`, input);
    }

    update(
        organisationId: string,
        transactionId: string,
        transaction: CreateTransactionRequest
    ): Promise<AxiosResponse> {
        return this.authPatch(
            `/${organisationId}/transactions/${transactionId}`,
            transaction
        );
    }

    delete(
        organisationId: string,
        transactionId: string
    ): Promise<AxiosResponse> {
        return this.authDelete(
            `/${organisationId}/transactions/${transactionId}`
        );
    }

    index(
        organisationId: string,
        params: IndexOptions
    ): Promise<AxiosResponse> {
        return this.authGet(`/${organisationId}/transactions`, params);
    }
}

const client = new TransactionApi();
export default client;
