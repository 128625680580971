import React, { useEffect, useState } from 'react';
import AppLayout from 'components/AppLayout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DashboardChart from '../../components/DashboardChart';
import ChartOfAccounts, {
    SerializedChartOfAccounts,
} from '../../models/chartOfAccounts';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import AccountApi from '../../services/apis/AccountApi';
import { AxiosError, AxiosResponse } from 'axios';
import { captureSentryError } from '../../plugins/sentry';
import ErrorHandler from '../../services/errors';
import { useSnackbar } from 'notistack';
import LoadingSpinner from '../../components/LoadingSpinner';
import DashboardCard from '../../components/DashboardCard';
import TimeSeriesGenerator from '../../services/time/timeSeriesGenerator';
import KeyValuePair, { DateNumberPair } from '../../models/keyValuePair';
import Currency from '../../models/currency';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [timeSeriesAccounts, setTimeSeriesAccounts] = useState<
        Map<Date, Array<ChartOfAccounts>>
    >(new Map<Date, Array<ChartOfAccounts>>());
    const [loadingTimeSeriesAccounts, setLoadingTimeSeriesAccounts] =
        useState<boolean>(true);

    const [loadingAccounts, setLoadingAccounts] = useState<boolean>(true);

    const [accounts, setAccounts] = useState<Array<ChartOfAccounts>>();

    const organisationId: string = useSelector(
        (state: GlobalState) => state.organisationId
    ) as string;
    const timezone: string =
        useSelector((state: GlobalState) => state.timezone) ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;

    const loadTimeSeriesAccounts = () => {
        setLoadingTimeSeriesAccounts(true);
        AccountApi.timeSeries(
            organisationId,
            TimeSeriesGenerator.generateMonthly(7, timezone)
        )
            .then((response: AxiosResponse) => {
                const apiAccounts = new Map<Date, Array<ChartOfAccounts>>();
                Object.keys(response.data.payload).forEach((key: string) => {
                    apiAccounts.set(
                        new Date(key),
                        response.data.payload[key].map(
                            (account: SerializedChartOfAccounts) => {
                                return new ChartOfAccounts(account);
                            }
                        )
                    );
                });
                setTimeSeriesAccounts(apiAccounts);
                setLoadingTimeSeriesAccounts(false);
            })
            .catch(handleSentryError);
    };

    const handleSentryError = (error: AxiosError) => {
        captureSentryError(error);
        enqueueSnackbar(ErrorHandler.errorMessage(error), {
            variant: 'error',
        });
    };

    const fetchDateNumberKeyPair = (
        accountName: string
    ): Array<DateNumberPair> => {
        const result = new Array<DateNumberPair>();

        timeSeriesAccounts.forEach((value, key) => {
            result.push(
                new KeyValuePair<Date, number>(
                    key,
                    fetchAccount(accountName, value).accountBalance
                        ?.credits as number
                )
            );
        });

        return result;
    };

    const fetchDifferenceDateNumberKeyPair = (
        firstAccountName: string,
        secondAccountName: string
    ): Array<DateNumberPair> => {
        const result = new Array<DateNumberPair>();

        timeSeriesAccounts.forEach((value, key) => {
            result.push(
                new KeyValuePair<Date, number>(
                    key,
                    (fetchAccount(firstAccountName, value).accountBalance
                        ?.credits as number) -
                        (fetchAccount(secondAccountName, value).accountBalance
                            ?.credits as number)
                )
            );
        });

        return result;
    };

    const currentAccount = (accountName: string): ChartOfAccounts => {
        const chartOfAccounts = Array.from(timeSeriesAccounts.values())[
            timeSeriesAccounts.size - 1
        ];
        return fetchAccount(accountName, chartOfAccounts);
    };

    const fetchAccount = (
        accountName: string,
        accounts: ChartOfAccounts[]
    ): ChartOfAccounts => {
        const account = accounts.find((account: ChartOfAccounts) => {
            return account.name.toLowerCase() === accountName.toLowerCase();
        });

        if (account) {
            return account;
        }

        throw new Error(
            `Could ont find ${accountName} account from chart of accounts`
        );
    };

    const loadChartOfAccounts = () => {
        setLoadingAccounts(true);
        AccountApi.index(organisationId, true)
            .then((response: AxiosResponse) => {
                setAccounts(
                    response.data.payload.map(
                        (account: SerializedChartOfAccounts) => {
                            return new ChartOfAccounts(account);
                        }
                    )
                );
                setLoadingAccounts(false);
            })
            .catch((error: Error) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
            });
    };

    useEffect(() => {
        loadTimeSeriesAccounts();
        loadChartOfAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLayout title="Dashboard">
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    {loadingTimeSeriesAccounts && (
                        <Grid xs={12} item>
                            <LoadingSpinner />
                        </Grid>
                    )}
                    {!loadingTimeSeriesAccounts && (
                        <React.Fragment>
                            <DashboardChart
                                yAxisLabel={`${
                                    currentAccount('income').name
                                } (${
                                    currentAccount('income').currency.symbol
                                })`}
                                timezone={timezone}
                                currency={currentAccount('income').currency}
                                data={fetchDateNumberKeyPair('income')}
                                label="Total Income"
                                lineColor={theme.palette.success.main}
                            />
                            <Grid item xs={12} md={4} lg={3}>
                                <DashboardCard
                                    amount={
                                        currentAccount('income').accountBalance
                                            ?.credits as number
                                    }
                                    currency={
                                        currentAccount('income').accountBalance
                                            ?.currency as Currency
                                    }
                                    label="Total Income"
                                />
                            </Grid>
                            <DashboardChart
                                yAxisLabel={`${
                                    currentAccount('expenses').name
                                } (${
                                    currentAccount('expenses').currency.symbol
                                })`}
                                timezone={timezone}
                                currency={currentAccount('expenses').currency}
                                data={fetchDateNumberKeyPair('expenses')}
                                label="Total Expenses"
                                lineColor={theme.palette.error.main}
                            />
                            <Grid item xs={12} md={4} lg={3}>
                                <DashboardCard
                                    amount={
                                        currentAccount('expenses')
                                            .accountBalance?.credits as number
                                    }
                                    currency={
                                        currentAccount('expenses')
                                            .accountBalance
                                            ?.currency as Currency
                                    }
                                    label="Total Expenses"
                                />
                            </Grid>
                            <DashboardChart
                                yAxisLabel={`Profits (${
                                    currentAccount('income').currency.symbol
                                })`}
                                timezone={timezone}
                                currency={currentAccount('income').currency}
                                data={fetchDifferenceDateNumberKeyPair(
                                    'income',
                                    'expenses'
                                )}
                                label="Total Profits"
                            />
                            <Grid item xs={12} md={4} lg={3}>
                                <DashboardCard
                                    amount={
                                        (currentAccount('income').accountBalance
                                            ?.credits as number) -
                                        (currentAccount('expenses')
                                            .accountBalance?.credits as number)
                                    }
                                    currency={
                                        currentAccount('expenses')
                                            .accountBalance
                                            ?.currency as Currency
                                    }
                                    label="Total Profits"
                                />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                {accounts !== undefined && (
                                    <DashboardCard
                                        amount={
                                            fetchAccount('income', accounts)
                                                .accountBalance
                                                ?.credits as number
                                        }
                                        currency={
                                            fetchAccount('income', accounts)
                                                .accountBalance
                                                ?.currency as Currency
                                        }
                                        label="Total Income"
                                        secondaryLabel="All Time"
                                    />
                                )}
                                {loadingAccounts && <LoadingSpinner />}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                {accounts !== undefined && (
                                    <DashboardCard
                                        amount={
                                            fetchAccount('expenses', accounts)
                                                .accountBalance
                                                ?.credits as number
                                        }
                                        currency={
                                            fetchAccount('expenses', accounts)
                                                .accountBalance
                                                ?.currency as Currency
                                        }
                                        label="Total Expenses"
                                        secondaryLabel="All Time"
                                    />
                                )}
                                {loadingAccounts && <LoadingSpinner />}
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                {accounts !== undefined && (
                                    <DashboardCard
                                        amount={
                                            (fetchAccount('income', accounts)
                                                .accountBalance
                                                ?.credits as number) -
                                            (fetchAccount('expenses', accounts)
                                                .accountBalance
                                                ?.credits as number)
                                        }
                                        currency={
                                            currentAccount('expenses')
                                                .accountBalance
                                                ?.currency as Currency
                                        }
                                        secondaryLabel="All Time"
                                        label="Total Profits"
                                    />
                                )}
                                {loadingAccounts && <LoadingSpinner />}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Container>
        </AppLayout>
    );
}
