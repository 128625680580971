import React, { useState, FormEvent, ChangeEvent } from 'react';
import AppLayout from 'components/AppLayout';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CreateIcon from '@material-ui/icons/Add';
import {
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import OrganisationApi, { CreateResponse } from 'services/apis/OrganisationApi';
import ValidationErrors from '../../services/errors/ValidationErrors';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOrganisationId } from '../../store';
import ErrorHandler from 'services/errors';
import CONSTANTS from 'consts';
import { captureSentryError } from '../../plugins/sentry';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CurrencySelector from '../../components/CurrencySelector';
import timezonesList from '../../services/time/timezones';

interface LocalState {
    name: string;
    formErrors: ValidationErrors;
    loading: boolean;
}

export default function OrganisationCreate() {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        mb1: {
            marginBottom: theme.spacing(3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        loadingSpinner: {
            color: theme.palette.secondary.main,
            position: 'absolute',
            marginTop: 5,
            marginLeft: -12,
        },
    }));

    const [currency, setCurrency] = useState('FCFA');
    const [timezone, setTimezone] = useState<string>(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const classes = useStyles();
    const [state, setState] = useState({
        loading: false,
        name: '',
        formErrors: new ValidationErrors(),
    } as LocalState);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const createOrganisation = (state: LocalState, token: string) => {
        OrganisationApi.create(state.name, currency, timezone, token)
            .then((response: AxiosResponse<CreateResponse>) => {
                enqueueSnackbar(response.data.message, { variant: 'info' });
                dispatch(setOrganisationId(response.data.payload.id));
                setState({ ...state, loading: false });
                navigate(CONSTANTS.ROUTES.PATHS.DASHBOARD);
            })
            .catch((error: Error | string) => {
                captureSentryError(error);
                enqueueSnackbar(ErrorHandler.errorMessage(error), {
                    variant: 'error',
                });
                setState({
                    ...state,
                    loading: false,
                    formErrors: ErrorHandler.getFormErrors(error),
                });
            });
    };

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        setState({
            ...state,
            loading: true,
        });

        if (executeRecaptcha) {
            executeRecaptcha('create_organisation')!
                .then((token: string) => {
                    createOrganisation(state, token);
                })
                .catch((error) => {
                    enqueueSnackbar(ErrorHandler.errorMessage(error), {
                        variant: 'error',
                    });
                    setState({
                        ...state,
                        loading: false,
                    });
                });
        }
    };

    return (
        <AppLayout title="Create Organisation">
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5} lg={4}>
                        <Card>
                            <CardContent>
                                <form
                                    className={classes.form}
                                    noValidate
                                    onSubmit={onSubmit}
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        disabled={state.loading}
                                        error={state.formErrors.has('name')}
                                        helperText={state.formErrors.first(
                                            'name'
                                        )}
                                        name="text"
                                        margin="normal"
                                        key="organization-name"
                                        label="Name"
                                        placeholder="Organisation name e.g Google"
                                        autoComplete="organisation-name"
                                        variant="outlined"
                                        value={state.name}
                                        onChange={(event: any) => {
                                            setState({
                                                ...state,
                                                name: event.target.value,
                                            });
                                        }}
                                    />
                                    <CurrencySelector
                                        label="Currency"
                                        className={classes.mb1}
                                        disabled={state.loading}
                                        error={state.formErrors.has('currency')}
                                        helperText={state.formErrors.first(
                                            'currency'
                                        )}
                                        value={currency}
                                        onChange={(
                                            event: ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            setCurrency(event.target.value);
                                        }}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel>Timezone</InputLabel>
                                        <Select
                                            label="Timezone"
                                            variant="outlined"
                                            placeholder="Timezone"
                                            disabled={state.loading}
                                            error={state.formErrors.has(
                                                'timezone'
                                            )}
                                            value={timezone}
                                            onChange={(
                                                event: React.ChangeEvent<{
                                                    value: unknown;
                                                }>
                                            ) => {
                                                setTimezone(
                                                    event.target.value as string
                                                );
                                            }}
                                        >
                                            {timezonesList.map(
                                                (key: string) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={key}
                                                    >
                                                        {key}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={state.loading}
                                        startIcon={<CreateIcon />}
                                        className={classes.submit}
                                    >
                                        {state.loading && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.loadingSpinner
                                                }
                                            />
                                        )}
                                        Create
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    );
}
