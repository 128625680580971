import React, { useState, FormEvent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Card, CardContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ValidationErrors from 'services/errors/ValidationErrors';
import firebase from 'plugins/firebase';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from 'store';
import User from 'models/user';
import { ROUTES } from 'consts';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    container: {
        minHeight: '100vh',
        width: '100vw',
        alignItems: 'center',
        display: 'flex',
    },
    loadingSpinner: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        marginTop: 5,
        marginLeft: -12,
    },
}));

interface LocalState {
    loading: boolean;
    email: string;
    password: string;
    formErrors: ValidationErrors;
}

export default function AuthLogin() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState({
        loading: false,
        email: '',
        password: '',
        formErrors: new ValidationErrors(),
    } as LocalState);

    const onLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setState({
            ...state,
            formErrors: new ValidationErrors(),
            loading: true,
        });

        firebase
            .auth()
            .signInWithEmailAndPassword(state.email, state.password)
            .then((credential: firebase.auth.UserCredential) => {
                if (credential.user) {
                    dispatch(
                        setUser(
                            User.serializedUserFromFirebaseUser(credential.user)
                        )
                    );
                    navigate(ROUTES.PATHS.DASHBOARD);
                }
            })
            .catch((error: { code: string; message: string }) => {
                const wrongCredentials = new Set(['auth/user-not-found']);
                const message = 'Invalid email or password';
                const formErrors = new ValidationErrors();
                if (wrongCredentials.has(error.code)) {
                    formErrors.add('email', message);
                    formErrors.add('password', message);
                } else {
                    formErrors.add('email', error.message);
                }
                enqueueSnackbar('Validation errors while signing in', {
                    variant: 'error',
                });
                setState({ ...state, loading: false, formErrors });
            });
    };

    return (
        <div className={classes.container}>
            <Container maxWidth="xs">
                <Card>
                    <CardContent>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <form
                                className={classes.form}
                                noValidate
                                onSubmit={onLogin}
                            >
                                <TextField
                                    required
                                    fullWidth
                                    disabled={state.loading}
                                    error={state.formErrors.has('email')}
                                    helperText={state.formErrors.first('email')}
                                    name="email"
                                    margin="normal"
                                    key="email"
                                    label="Email"
                                    autoComplete="email"
                                    variant="outlined"
                                    autoFocus
                                    value={state.email}
                                    onChange={(event: any) => {
                                        setState({
                                            ...state,
                                            email: event.target.value,
                                        });
                                    }}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    disabled={state.loading}
                                    error={state.formErrors.has('password')}
                                    helperText={state.formErrors.first(
                                        'password'
                                    )}
                                    margin="normal"
                                    name="password"
                                    key="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="password"
                                    variant="outlined"
                                    value={state.password}
                                    onChange={(event: any) => {
                                        setState({
                                            ...state,
                                            password: event.target.value,
                                        });
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    disabled={state.loading}
                                    startIcon={<LockOpenIcon />}
                                    className={classes.submit}
                                >
                                    {state.loading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.loadingSpinner}
                                        />
                                    )}
                                    Sign In
                                </Button>
                            </form>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
