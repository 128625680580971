import React, { MouseEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import ValidationErrors from '../services/errors/ValidationErrors';
import ChartOfAccounts from '../models/chartOfAccounts';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TransactionInput, {
    InputGroupedTransaction,
    InputTransaction,
    makeTransaction,
} from './TransactionInput';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export interface TransactionFormProps {
    isLoading: boolean;
    formErrors: ValidationErrors;
    transaction: GroupedTransaction;
    accounts: Array<ChartOfAccounts>;
    onChange: (newTransaction: InputGroupedTransaction) => void;
}

export interface GroupedTransaction extends InputTransaction {
    children: Array<InputTransaction>;
}

const useStyles = makeStyles((theme: Theme) => ({
    addTransaction: {
        marginTop: '-20px;',
    },
    dividerContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },

    transactionDivider: {
        marginTop: theme.spacing(2),
    },

    mx2: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

export default function TransactionForm(props: TransactionFormProps) {
    const classes = useStyles();

    function addTransaction(event: MouseEvent) {
        event.preventDefault();
        props.onChange({
            ...props.transaction,
            children: [...props.transaction.children, makeTransaction()],
        });
    }

    function onChildChange(newTransaction: InputTransaction) {
        props.onChange({
            ...props.transaction,
            children: props.transaction.children.map(
                (transaction: InputTransaction) => {
                    if (transaction.id === newTransaction.id) {
                        return newTransaction;
                    }
                    return transaction;
                }
            ),
        });
    }

    function onParentChange(newTransaction: InputTransaction) {
        props.onChange({
            ...props.transaction,
            ...newTransaction,
        });
    }

    function deleteTransaction(transactionId: string) {
        props.onChange({
            ...props.transaction,
            children: props.transaction.children.filter(
                (transaction: InputTransaction) => {
                    return transaction.id !== transactionId;
                }
            ),
        });
    }

    const formErrorsForChild = (id: string): ValidationErrors => {
        const result = new ValidationErrors();
        props.formErrors.keys().forEach((key: string) => {
            if (key.indexOf(`Children[${id}].`) !== -1) {
                result.addMany(
                    key.replace(`Children[${id}].`, ''),
                    props.formErrors.get(key)
                );
            }
        });
        return result;
    };

    return (
        <Grid container style={{ marginTop: 1, marginBottom: 1 }}>
            <Grid item xs={12}>
                <TransactionInput
                    isLoading={props.isLoading}
                    formErrors={props.formErrors}
                    transaction={props.transaction}
                    accounts={props.accounts}
                    onChange={onParentChange}
                />
            </Grid>
            <Grid item xs={12} className={classes.dividerContainer}>
                <Box style={{ display: 'flex' }}>
                    <Box style={{ flexGrow: 1 }}>
                        <Divider variant="middle" />
                    </Box>
                    <Box>
                        <Button
                            disabled={props.isLoading}
                            className={classes.addTransaction}
                            color="secondary"
                            size="small"
                            variant="contained"
                            onClick={addTransaction}
                        >
                            Add Transaction
                        </Button>
                    </Box>
                    <Box style={{ flexGrow: 1 }}>
                        <Divider variant="middle" />
                    </Box>
                </Box>
            </Grid>
            {props.transaction.children.map((transaction: InputTransaction) => {
                return (
                    <Grid item xs={12} key={transaction.id}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Box flexGrow={1}>
                                <TransactionInput
                                    isLoading={props.isLoading}
                                    formErrors={formErrorsForChild(
                                        transaction.id
                                    )}
                                    transaction={transaction}
                                    accounts={props.accounts}
                                    onChange={onChildChange}
                                />
                            </Box>
                            <Box className={classes.mx2}>
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        deleteTransaction(transaction.id)
                                    }
                                >
                                    <DeleteIcon fontSize="large" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider
                            variant="middle"
                            className={classes.transactionDivider}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}
