import React, { ChangeEvent } from 'react';
import { ListSubheader, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ChartOfAccounts from '../models/chartOfAccounts';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface AccountSelectorProps {
    disabled: boolean;
    error: boolean;
    accounts: Array<ChartOfAccounts>;
    helperText: string | undefined;
    value: string | null;
    label: string | undefined;
    onChange: (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        account: {
            paddingLeft: theme.spacing(4),
        },
    })
);

export default function AccountSelector(props: AccountSelectorProps) {
    const classes = useStyles();
    const accountIsVisible = (account: ChartOfAccounts) => !account.hidden;
    const options = props.accounts.map((account: ChartOfAccounts) => {
        return account.children.map((subAccount: ChartOfAccounts) => {
            return [
                <ListSubheader>{subAccount.name}</ListSubheader>,
                ...subAccount.children
                    .filter(accountIsVisible)
                    .map((subSubAccount: ChartOfAccounts) => {
                        return (
                            <MenuItem
                                className={classes.account}
                                key={subSubAccount.id}
                                value={subSubAccount.id}
                            >
                                {subSubAccount.name}
                            </MenuItem>
                        );
                    }),
            ];
        });
    });
    return (
        <TextField
            required
            fullWidth
            size="small"
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            select
            name="account"
            margin="normal"
            key="account"
            label={props.label ? props.label : 'Currency'}
            placeholder="Account"
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
        >
            {options}
        </TextField>
    );
}
