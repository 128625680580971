import { TablePaginationOptions } from '../types/material-ui';
import { IndexOptions } from '../services/apis/BaseApi';
import { SortParams } from '../components/TransactionFilter';

class Transformers {
    NewIndexOptions(
        option: TablePaginationOptions,
        params: SortParams
    ): IndexOptions {
        return {
            sortBy: params.sortBy,
            sortOrder: params.sortOrder,
            query: params.query,
            take: option.countPerPage,
            skip: option.countPerPage * option.page,
        };
    }
}

const instance = new Transformers();
export default instance;
