import React from 'react';
import Dashboard from 'pages/dashboard/DashboardIndex';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthLogin from 'pages/auth/AuthLogin';
import NotFound from 'pages/utils/UtilNotFound';
import { setUser } from 'store';
import firebase from 'plugins/firebase';
import { useDispatch } from 'react-redux';
import User, { userIsLoggedIn } from 'models/user';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import OrganisationCreate from 'pages/organisation/OrganisationCreate';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CONSTANTS from 'consts';
import AccountsIndex from './pages/accounts/AccountsIndex';
import TransactionsIndex from './pages/transactions/TransactionsIndex';
import CurrencyPairIndex from './pages/currency-pair/CurrencyPairIndex';

export default function App() {
    const dispatch = useDispatch();

    firebase.auth().onAuthStateChanged((user) => {
        dispatch(setUser(User.serializedUserFromFirebaseUser(user)));
    });
    firebase.auth().onIdTokenChanged((user) => {
        dispatch(setUser(User.serializedUserFromFirebaseUser(user)));
    });

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: '#64b5f6',
                    },
                    type: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode]
    );

    // @ts-ignore
    const ProtectedRoute = ({ children }) => {
        if (!userIsLoggedIn()) {
            return <Navigate to={CONSTANTS.ROUTES.PATHS.AUTH.LOGIN} replace />;
        }
        return children;
    };

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.AUTH.LOGIN}
                            element={<AuthLogin />}
                        />
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.DASHBOARD}
                            element={
                                <ProtectedRoute>
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.ORGANISATION.CREATE}
                            element={
                                <ProtectedRoute>
                                    <OrganisationCreate />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.ACCOUNTS.INDEX}
                            element={
                                <ProtectedRoute>
                                    <AccountsIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.CURRENCY_PAIR.INDEX}
                            element={
                                <ProtectedRoute>
                                    <CurrencyPairIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={CONSTANTS.ROUTES.PATHS.TRANSACTIONS.INDEX}
                            element={
                                <ProtectedRoute>
                                    <TransactionsIndex />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    );
}
